#banner {
    height: 470px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#banner .row, .menu-card .row {
    background: linear-gradient(90deg, rgba(30, 30, 30, 0.15458683473389356) 0%, rgba(30, 30, 30, 1) 100%);
}

#banner img, .divider {
    width: 200px;
}

.branch-item-card {
    background-color: transparent;
    color: var(--text);
}

.card-image-des {
    height: 180px;
    object-fit: cover;
    animation: topdown 1.2s linear alternate infinite;
}

.animated-img {
    animation: topdown 1.2s linear alternate infinite;
}

img[alt="menu_item"] {
    width: 100%;
    height: 250px;
    object-fit: cover !important;
}

@keyframes topdown {
    from {
        transform: translateY(-10px);
    }
    to {
        transform: translateY(10px);
    }
}

@media screen and (max-width: 1000px) {
    img[alt="menu_item"] {
        height: 150px;
    }
}

@media screen and (max-width: 600px) {
    #banner {
        height: 280px;
    }

    .next, .prev {
        display: none;
    }
}

