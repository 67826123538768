/* menu card */

.menu-card {
    height: 250px;
    background-size: cover;
    background-position: center;
    background-image: url("./../../../../original/image/Rectangle 14.webp");
}

.menu-card img {
    margin-right: -17px !important;
}

/* branch */

.branch-img {
    width: 100%;
    height: 230px;
    object-fit: cover;
}

.category-father button {
    width: auto;
}

@media screen and (max-width: 600px) {
    /* category */
    .category-father {
        width: 100%;
        overflow-x: scroll;
    }

    .category-father::-webkit-scrollbar {
        display: none;
    }

    .category-father .d-flex {
        width: 100%;
    }
}