/* var */
:root {
    --theme: #1E1E1E;
    --title: #C07B36;
    --text: #FEFEFE;
    --small: 10px;
    --small-medium: 12px;
    --medium: 14px;
    --big: 18px;
}

/* font */
@font-face {
    font-family: IRANYekanXFaNum;
    src: url('./original/font/IRANYekan/Farsi numerals/Webfonts/Woff/IRANYekanXFaNum-Regular.woff') format('woff');
}

/* base style */

* {
    outline: none;
    text-align: justify;
    transition: 0.4s all;
    box-sizing: border-box;
    text-decoration: none;
    font-family: IRANYekanXFaNum, serif;
}

html {
    scroll-behavior: smooth;
}

body {
    direction: rtl;
    overflow-x: hidden !important;
    background-color: var(--theme);
}


/* scrollbar */
body::-webkit-scrollbar, .modal-body::-webkit-scrollbar {
    width: 6px;
    border-radius: 8px;
}

body::-webkit-scrollbar-thumb, .modal-body::-webkit-scrollbar {
    width: 6px;
    border-radius: 8px;
    background-color: var(--title);
}

/* buttons */

.btn-site {
    color: var(--text) !important;
    background-color: var(--title) !important;
}

.btn-outline-site, .btn-outline {
    color: var(--title) !important;
    border-color: var(--title) !important;
    background-color: var(--theme) !important;
}

.btn-outline-site:hover {
    color: var(--text) !important;
    background-color: var(--title) !important;
}

/* text and fonts */

.text-site, .card-title {
    color: var(--title);
}

.card-text {
    line-height: 30px;
    color: var(--text);
}

.font-14 {
    font-size: var(--medium);
}

.swal2-title {
    font-size: 16px !important;
    color: var(--text) !important;
}

.font-12 {
    font-size: var(--small-medium);
}

/* hover card */
.card {
    border: 0 !important;
}

.list-group-item {
    background-color: transparent !important;
}

.hover-card {
    transition: 1s all !important;
}

.hover-card:hover {
    scale: 1.03;
    cursor: pointer;
}

/* responsive */

@media screen and (max-width: 600px) {
    body {
        padding-bottom: 120px;
    }

    .modal-body::-webkit-scrollbar {
        display: none;
    }

    .card-title {
        font-size: 24px !important;
    }

    .branch-item-card .card-title, .menu-card .card-title, .worker-card .card-title {
        font-size: 20px !important;
    }

    .gallery-card .card-title, #progogram-modal .card-title {
        font-size: 16px !important;
    }

    .card-text, .btn, button, .btn-phone {
        font-size: var(--medium) !important;
    }
}