/*  logo */
.logo {
    height: 40px;
    width: 100px;
    object-fit: cover;
}

.logo-big {
    height: 100px;
    width: 100px;
    object-fit: cover;
}

/* mobile header */
.header-mobile {
    bottom: -2px !important;
    z-index: 999 !important;
    background-color: var(--theme);
    box-shadow: 5px 5px 55px 0 rgba(0, 0, 0, 0.15);
}

.active {
    font-weight: 600;
    color: var(--title) !important;
}

.btn-phone .active, .container-fluid .btn.active {
    background-color: var(--title) !important;
    color: var(--text) !important;
}
