/* gallery card */
.gallery-card {
    background-color: transparent;
}

.gallery-card img {
    height: 250px;
    object-fit: cover;
}


/* navigators */

.prev, .next {
    top: 40%;
    position: absolute;
    z-index: 9999 !important;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

/* bullet*/

.swiper-pagination-bullet-active {
    background-color: var(--title) !important;
    padding-left: 20px;
    border-radius: 10px;
}

/* like */

.HeartAnimation {
    padding-top: 2em;
    background-image: url('./../../../../original/image/web_heart_animation.png');
    background-repeat: no-repeat;
    background-size: 3900%;
    background-position: left;
    height: 35px;
    width: 35px;
    margin: 0 0 0 -20px;
    cursor: pointer;
}

.animate {
    animation: heart-burst .1s steps(28) forwards;
}

.like span {
    margin-bottom: -2px;
}

.active-like span {
    margin-left: -11px;
}

/* upload */

.modal .form .btn {
    height: 56px;
}

.upload-btn {
    height: 56px;
    width: 100% !important;
    text-align: start;
    color: var(--text) !important;
    font-size: 14px;
    padding-right: 10px;
    border-radius: 8px;
    border: 1px dashed var(--title);
    background-color: transparent !important;
}

.img-uploader-card {
    border: 1px dashed var(--title) !important;
    height: 200px !important;
    border-radius: 10px !important;
    background-color: transparent !important;
}

.image-upload-done {
    border-radius: 8px;
    width: 100px !important;
    height: 100px !important;
    object-fit: cover !important;
}

.image-upload-done-b {
    width: 100% !important;
    height: 100px !important;
    object-fit: cover;
}

.img-uploader-card .upload-btn {
    padding-right: 0 !important;
    height: 40px !important;
}

.image_error {
    font-size: 10px;
    color: #ABABAB;
}

/* form */

.modal-content {
    background-color: var(--theme) !important;
}

.form-floating label {
    right: auto !important;
    left: auto !important;
    background-color: transparent !important;
    color: var(--text) !important;
}

.form-floating > input:not(:focus):placeholder-shown + label {
    background: var(--theme);
}

.form-floating > input + label:after {
    background: var(--theme) !important;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
    transform: scale(.85) translateY(-1.8rem) translateX(0.8rem) !important;
}

.form-control, .form-select {
    direction: rtl !important;
    border-color: var(--title) !important;
    outline: 0 !important;
    box-shadow: none !important;
    font-size: 14px !important;
    background-color: transparent !important;
    color: var(--text) !important;
}

.form-control:focus {
    border-color: var(--title) !important;
}

@keyframes heart-burst {
    0% {
        background-position: left
    }
    100% {
        background-position: right;
        margin: 0 0 0 -11px;
    }
}