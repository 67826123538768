.progogram-logo {
    width: 75px;
    height: 75px;
    object-fit: cover;
}

.team-profile {
    width: 50px;
    height: 50px;
    object-fit: cover;
}